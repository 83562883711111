import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_COHORT = gql`
  query cohort($cohort: String!) {
    cohort(cohort: $cohort) {
      id
      name
      trainers
      created
      contacts
      options {
        immediate_flow
        individual_purchase
        price
        expires_at
        max_participants
        includes_retake
        demographicQuestions
        contactsLimited
        enableTrainersViewReport
        testCohort
      }
      surveysCount
    }
  }
`;

const GET_COHORTS_FOR_ADMIN = gql`
  query adminCohorts {
    adminCohorts {
      orgs {
        id
        name
      }
      cohorts {
        id
        name
      }
    }
  }
`;

const SAVE_COHORT_EMAIL_SEQUENCE = gql`
  mutation addEmailSequence(
    $emailSequenceOptions: EmailSequenceOptionsInput
    $cohortId: ID
  ) {
    addEmailSequence(emailSequenceOptions: $emailSequenceOptions, cohortId: $cohortId)
  }
`;

const useCohort = () => {
  const [
    addEmailSequence,
    { loading: addEmailSequenceLoading, error: addEmailSequenceError },
  ] = withSessionHandler(useMutation(SAVE_COHORT_EMAIL_SEQUENCE));

  const getCohort = ({ cohort }) => {
    const doc = withSessionHandler(
      useQuery(GET_COHORT, {
        variables: {
          cohort,
        },
        skip: !cohort,
      })
    );
    return { loading: doc.loading, result: doc.data };
  };

  const getCohortsForAdmin = () => {
    const doc = withSessionHandler(
      useQuery(GET_COHORTS_FOR_ADMIN, { fetchPolicy: 'no-cache' })
    );
    return { loading: doc.loading, result: doc.data };
  };

  return {
    getCohort,
    addEmailSequence,
    addEmailSequenceLoading,
    addEmailSequenceError,
    getCohortsForAdmin,
  };
};

export default useCohort;
