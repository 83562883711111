import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_SURVEY = gql`
  query getSurvey($id: String!) {
    getSurvey(id: $id) {
      id
      created
      previousSurveyId
      completed
      cohort_id
      submitted
      survey {
        you {
          id
          name
          network
          email
          leader
          gender
          age
          industry
          department
          employeeCount
        }
        network {
          id
          name
          network
          where
          company
          position
          leader
          gender
          age
          sameEthnicity
          years
          relationship
          work
          career
          support
          growth
          mentoring
          friend
          motivators
          innovators
        }
        version
      }
    }
  }
`;

const useSurvey = () => {
  const getSurvey = ({ id }) => {
    const doc = withSessionHandler(
      useQuery(GET_SURVEY, {
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        skip: !id,
      })
    );
    return { loading: doc.loading, result: doc.data, errors: doc.error };
  };

  return { getSurvey };
};

export { useSurvey, GET_SURVEY };
