import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const GET_LAST_SURVEY_BY_USER_AND_COHORT = gql`
  query lastSurvey($cohortId: String!, $email: String!, $completed: Boolean) {
    lastSurvey(cohortId: $cohortId, email: $email, completed: $completed) {
      id
      created
      previousSurveyId
      completed
      lastPage
      cohort_id
      survey {
        you {
          id
          name
          network
          email
          leader
          gender
          age
          industry
          department
          employeeCount
          careerOutcomes
          careerSatisfaction {
            satisfying
            enthusiastic
            interesting
            opportunities
          }
          ethnicity
          education
          experience
          resideInUs
        }
        network {
          id
          name
          network
          where
          company
          position
          leader
          gender
          age
          sameEthnicity
          years
          relationship
          work
          career
          support
          growth
          mentoring
          friend
          motivators
          innovators
        }
      }
    }
  }
`;

const GET_LAST_SURVEY_BY_USER = gql`
  query userLastSurvey($email: String!) {
    userLastSurvey(email: $email) {
      id
      created
      completed
      previousSurveyId
      lastPage
      survey {
        you {
          id
          name
          network
          email
          leader
          gender
          age
          industry
          department
          employeeCount
          careerOutcomes
          careerSatisfaction {
            satisfying
            enthusiastic
            interesting
            opportunities
          }
          ethnicity
          education
          experience
          resideInUs
        }
        network {
          id
          name
          network
          where
          company
          position
          leader
          gender
          age
          sameEthnicity
          years
          relationship
          work
          career
          support
          growth
          mentoring
          friend
          motivators
          innovators
        }
      }
    }
  }
`;

export const GET_SURVEY_BY_USER = gql`
  query userSurveys {
    userSurveys {
      surveys {
        id
        created
        completed
        cohort_id
        immediateFlow
        completed
        lastPage
        submitted
        editWindow
        survey {
          you {
            id
            name
            network
            email
            leader
            gender
            age
            industry
            department
            employeeCount
          }
          network {
            id
            name
            network
            where
            company
            position
            leader
            gender
            age
            sameEthnicity
            years
            relationship
            work
            career
            support
            growth
            mentoring
            friend
            motivators
            innovators
          }
          version
        }
      }
    }
  }
`;

const GET_USER_SURVEY_BY_COHORT = gql`
  query userSurveysByCohort($email: String!, $cohort: String!, $skip: Int, $limit: Int) {
    userSurveysByCohort(email: $email, cohort: $cohort, skip: $skip, limit: $limit) {
      totalCount
      surveys {
        id
        created
        cohort_id
        completed
        updated_at
        submitted
      }
    }
  }
`;

const useSurveyByUserAndCohort = () => {
  const getLastSurvey = ({ cohortId, email, completed }) => {
    const doc = withSessionHandler(
      useQuery(GET_LAST_SURVEY_BY_USER_AND_COHORT, {
        variables: {
          cohortId,
          email,
          completed,
        },
        fetchPolicy: 'no-cache',
      })
    );
    return { loading: doc.loading, result: doc.data };
  };

  const getUserSurveyByCohort = ({ cohortId, email, skip = 0, limit = 10 }) => {
    const doc = withSessionHandler(
      useQuery(GET_USER_SURVEY_BY_COHORT, {
        variables: {
          cohort: cohortId,
          email,
          skip,
          limit,
        },
        fetchPolicy: 'no-cache',
      })
    );
    return { loading: doc.loading, result: doc.data?.userSurveysByCohort };
  };

  return { getLastSurvey, getUserSurveyByCohort };
};

const useSurveyByUser = () => {
  const getUserSurveys = () => {
    const doc = withSessionHandler(
      useQuery(GET_SURVEY_BY_USER, {
        fetchPolicy: 'no-cache',
      })
    );
    return doc.data;
  };

  const getLastCompletedSurvey = ({ email }) => {
    const doc = withSessionHandler(
      useQuery(GET_LAST_SURVEY_BY_USER, {
        variables: {
          email,
        },
        fetchPolicy: 'no-cache',
      })
    );
    return { loading: doc.loading, result: doc.data };
  };
  return { getUserSurveys, getLastCompletedSurvey };
};

export {
  useSurveyByUserAndCohort,
  useSurveyByUser,
  GET_LAST_SURVEY_BY_USER_AND_COHORT,
  GET_LAST_SURVEY_BY_USER,
};
